import _ from 'lodash'

export const filterTemplate = (templateList) => {

    const tmpStandard:any = []
    const tmpRemove:any = []

    _.forEach(templateList, (template:any) => {
        if(template?.placeholderMode === 'upper' || _.includes(template?.templateId, 'remove')) {
            tmpRemove.push(template.templateId)
        } else {
            tmpStandard.push(template.templateId)
        }
    })

    const defaultStandardList = ['standard001pro', 'standard002pro']
    const defaultRemoveList = ['remove001pro']

    const standardList = tmpStandard?.length == 0 ? defaultStandardList : tmpStandard
    const removeList = tmpRemove?.length == 0 ? defaultRemoveList : tmpRemove

    return {
        standardList,
        removeList,

        defaultStandardList,
        defaultRemoveList,
    }
}
