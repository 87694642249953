
import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'

import { useAppStateStore } from './appState'
import { useWishStore } from './wish'
import { useAuthStore } from './auth'
import { useEventStore } from './event'
import { useTemplateStore } from './template'

import { doc, getDoc, setDoc, updateDoc, deleteDoc,  } from 'firebase/firestore'

import _ from 'lodash'
import dayjs from 'dayjs'
import { makeId } from '~/utils/makeId'



export const useRestorePageStore = defineStore({
  id: 'restorePage',
  state: () => {
    return {
      isRestorePage: false,
      isSkipRestored: false,
      restoreData: null as any
    }
  },
  actions: {
    async getRestorePage() {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      const nodeName = `variant_${eventId}`
      let refId = window.localStorage.getItem(nodeName)

      if(!refId) {
        return
      }

      const docRef = doc(db, 'tmpWishPage',refId)
      const response = await getDoc(docRef)
      if(!response.exists()) {
        this.isRestorePage = false
        this.restoreData = null
        console.log(`refId: ${refId} not exist`)
        return 
      }

      const data = response.data()
      

      // --- check ttl ---
      const ttl = dayjs(data?.ttl)
      const currentData = new Date().toISOString()
      const diff = ttl.diff(currentData, 'minute')
      if(diff <= 0) {
        await this.removeRestorePage()
        this.isRestorePage = false
        this.restoreData = null
        console.log('time to leave')
        return 
      }

      // --- check data ---
      if(!data?.form && !data?.guestImage) {
        this.isRestorePage = false
        this.restoreData = null
        console.log('not have restore page data')
        return 
      }

      this.isRestorePage = true
      this.restoreData = {
        ...data,
        page: data?.form?.wish && data?.guestImage?.image?.cfId ? data?.page : 'selectUploadAndWish'
      }

      // console.log('restore page -->', data)
    },

    async setWishAndRestoreToPage() {
      if(!this.isRestorePage) {
        return 
      }

      const eventStore = useEventStore()
      const wishStore = useWishStore()
  
      wishStore.wishForm = this.restoreData?.form 

      if((_.size(this?.restoreData?.guestImage) > 0)) {
        await wishStore.updateGuestImageObj(this?.restoreData?.guestImage)
      }

      useRouter().push(`/${this.restoreData?.page}?eventId=${eventStore?.eventId}`)
    },

    async updateTmpRestorePage(page: string, payload: any) {
      const eventStore = useEventStore()
      const wishStore = useWishStore()

      const eventId = eventStore.eventId

      const nodeName = `variant_${eventId}`
      let refId = window.localStorage.getItem(nodeName)
      let docId = null

      if(refId) {
        docId = refId
      } else {
        docId = `${eventId}_${makeId(12)}`
      }

      const currentDate = new Date();
      const futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + 3);

      const pagePayload = {
        ...payload,
        eventId: eventId,
        page: page,
        timestamp: new Date().toISOString(),
        ttl: futureDate.toISOString(),
        wishId: wishStore?.wishDocId
      }
      // console.log(pagePayload)

      const docRef = doc(db, 'tmpWishPage', docId)
      await setDoc(docRef, pagePayload, { merge: true })
      window.localStorage.setItem(nodeName, docId)
      console.log('update tmpWishPage success: ', docId)
    },

    async removeRestorePage() {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      const nodeName = `variant_${eventId}`
      let refId = window.localStorage.getItem(nodeName)

      if(!refId) {
        return console.log('refId is not defined')
      }

      window.localStorage.removeItem(nodeName)
      const docRef = doc(db, 'tmpWishPage', refId)
      await deleteDoc(docRef)
      console.log('remove tmp restore page success')
    }
  },
  getters: {}
})