import { useRenderBase } from "./useRenderBase"
import { useRenderLoading } from "./useRenderLoading"
import { useUseRenderText } from "./useRenderText"
import { useRenderTransformer } from "./useRenderTransformer"
import { useUseRenderZoom } from "./useRenderZoom"

export const useRenderEditorV3 = async ({
  container,
  template,
  wishData,
  variant,
  guestBlobImage,
  debug = false,
  loadingOptions = {}
}) => {

  const { wish, name, background, stage, layer, transformer, placeholder, groupPlaceholder } = useRenderBase({
    container,
    template,
    wishData,
    variant,
    guestBlobImage,
    debug
  })

  useRenderTransformer({
    layer,
    placeholder,
    transformer,
    groupPlaceholder
  })

  const {
    zoomIn,
    zoomOut,
    zoomReset
  } = useUseRenderZoom({
    layer,
    placeholder,
    groupPlaceholder,
    template
  })

  function exportWishData() {

    let data = {
      ...wishData,
      version: "3"
    }

    data.guest.imageAttrs = {
      x: placeholder.x(),
      y: placeholder.y(),
      width: placeholder.width(),
      height: placeholder.height(),
      scaleX: placeholder.scaleX(),
      scaleY: placeholder.scaleY(),
      rotation: placeholder.rotation() || 0,
    };

    console.log(data)
    return data
  }
  useUseRenderText({layer, wish, name, template})
  useRenderLoading({loadingOptions , layer, placeholder, name, wish, background })

  return {
    stage,
    zoomIn,
    zoomOut,
    zoomReset,
    exportWishData
  }
}
