import _ from 'lodash'

export const mappingTemplateId = (templateId: string) => {
  if(_.includes(templateId, 'pro')) {
    return templateId
  }
  // Split the templateId into parts - 'standard' and '01'
  const base = templateId.slice(0, -2);    // 'standard'
  const numberPart = templateId.slice(-2);  // '01'

  // Pad the number part with another 0 to make it '001'
  const paddedNumber = numberPart.padStart(3, '0');

  // Concatenate the base, padded number, and 'pro' to form the new ID
  const newId = `${base}${paddedNumber}pro`;
  return newId
}