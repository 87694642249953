
import { ref } from "vue";

export const useUseRenderZoom = ({
  layer,
  placeholder,
  groupPlaceholder,
  template,
}) => {

  const frame = new Konva.Rect({
    x: 0,
    y: 0,
    width:   template.elements.placeholder.width *   template.elements.placeholder.scaleX ,    //  groupPlaceholder.clip().width,
    height: template.elements.placeholder.height *  template.elements.placeholder.scaleY, //  groupPlaceholder.clip().height,
    stroke: 'black',
    opacity: 0,
    fill: 'orange',
    listening: false,
    rotation: 0,
  })

  groupPlaceholder.add(frame)

  return {
    zoomIn,
    zoomOut,

    zoomReset,
    // zoomFit
  }


  function zoomIn() {

    var scaleBy = 1.1;
    var oldScale = placeholder.scaleX();

    var newScale = oldScale * scaleBy;

    placeholder.scale({ x: newScale, y: newScale });
    layer.batchDraw();
  }

  function zoomOut( { zoomReset = false}) {

    var scaleBy = 0.9;
    if (zoomReset) {
      var scaleBy = 0.01;
    }

    var image = placeholder;

    var scaleX = image.scaleX();
    var scaleY = image.scaleY();

    let newScaleX = scaleX * scaleBy;
    let newScaleY = scaleY * scaleBy;


    // Minimum scale to cover the placeholder
    var minScaleX = frame.width() / image.width()
    var minScaleY = frame.height() / image.height()



    let newSC = null

    let max = Math.max(minScaleX, minScaleY)
    // Enforce minimum scale
    if (newScaleX < minScaleX) {
      newSC = max;
    }
    if (newScaleY < minScaleY) {
      newSC = max;
    }

    image.scaleX(newSC || newScaleX);
    image.scaleY(newSC || newScaleY);

    // Ensure image covers placeholder after scaling
    var pos = image.position();
    var scaledWidth = image.width() * newScaleX;
    var scaledHeight = image.height() * newScaleY;

    var minX = frame.width() - scaledWidth;
    var minY = frame.height() - scaledHeight;

    if (pos.x < minX) {
      pos.x = minX;
    }
    if (pos.x > 0) {
      pos.x = 0;
    }
    if (pos.y < minY) {
      pos.y = minY;
    }
    if (pos.y > 0) {
      pos.y = 0;
    }

    image.position(pos);
    if (zoomReset) {
       // move to center frame
       let pos = placeholder.position();
       pos.x = (frame.width()  - placeholder.width()) / 2;
       pos.y = (frame.height()  - placeholder.height()) / 2;
       placeholder.position(pos);

    }


    layer.batchDraw();
  }

  function zoomReset() {
     zoomOut({ zoomReset: true })


  }



}
