import { defineStore } from 'pinia'
import { db, storage } from '~/service/firebase_config'
import { collection, doc, getDoc, getDocs, setDoc, query, where, onSnapshot, deleteDoc } from 'firebase/firestore'
import { useAppStateStore } from './appState'
import { useEventStore } from './event'
import { useAuthStore } from './auth'
import { useWishStore } from './wish'
import { ref, uploadBytes, deleteObject, uploadBytesResumable } from "firebase/storage";

import _cloneDeep from 'lodash/cloneDeep.js'
import _filter from 'lodash/filter.js'
import _uniqBy from 'lodash/uniqBy.js'
import _orderBy from 'lodash/orderBy.js'
import _concat from 'lodash/concat.js'

import { makeId } from '../utils/makeId'
import dayjs from 'dayjs'
import axios from 'axios'
import delay from '~/utils/delay'


export const usePromptpay = defineStore({
  id: 'promptpayReceipt',
  state: () => {
    return {
      allReceipts: [] as any[],
      myReceipts: [] as any,
      uploadPercentage: 0,
      uploadTask: null,


      devUsersIds: ['U31755c7e54ce12ea295e33561895b2ec', 'U79cb50d879d85d2641e2ca77ff9c5816']
    }
  },
  actions: {
    fetchPromptpayReceipts() {
      const p = new Promise(async resolve => {
        const eventStore = useEventStore()
        const authStore = useAuthStore()

        const eventId = eventStore.eventId
        const holderDemo = eventStore.holderDemo
        const userId = authStore.userId

        const docRef = collection(db, `events/${eventId}/promptpay`)
        const q = query(docRef, where("userId", "==", userId), where("eventId", "==", eventId));

        onSnapshot(q, (querySnapshot) => {
          let tmpList: any = []

          if (holderDemo) {
            tmpList = mockReceipt()
          }

          querySnapshot.forEach(async (doc) => {
            const data = doc.data()
            tmpList.push(data)
          });

          const allUniqList = _uniqBy(tmpList, 'key')
          const allSorted = _orderBy(allUniqList, ['createAt'], ['asc'])
          this.allReceipts = allSorted

          const filtered = _filter(tmpList, p => p.status === 'success')
          const uniqList = _uniqBy(filtered, 'key')
          const sorted = _orderBy(uniqList, ['createAt'], ['asc'])

          this.myReceipts = sorted
          resolve(true)
        })
      })

      return p
    },

    // ---- uplaod / create----
    uploadReceipt(filename: string, file: any, promptpayId: string, callbakFunctionForBase64: any) {
      const appStateStore = useAppStateStore()
      const authStore = useAuthStore()
      const eventStore = useEventStore()
      const userId = authStore.userId
      const eventId = eventStore.eventId

      const p = new Promise(async (resolve, reject) => {
        try {
          const folderName = 'promptpay_receipts'
          const imagePath = `events/${eventId}/${folderName}/${filename}` // storage
          // const imagePath = `queue/${filename}` // cloudflare
          const metadata = {
            customMetadata: {
              path: "promptpay_receipts",
              project: 'photowishv2',
              eventId: eventId,
              userId: userId,
              promptpayId: promptpayId,
            }
          }
          const storageRef = ref(storage, imagePath);

          // -- upload  with percent --
          if (this.uploadTask) {
            this.uploadTask.cancel()
            this.uploadTask = null
          }

          //create timer if upload not progess move for 5 sec sicnce snapshot change 
          let timer = null
          let MILLISECS = 4000
          let timeout = () => {
            console.log('upload not progress')
            appStateStore.loading = false
            reject( {
              code : 'timeout'
            })
          }
          
          timer = setTimeout(timeout, MILLISECS)

          const uploadTask = uploadBytesResumable(storageRef, file, metadata)
          this.uploadTask = uploadTask;
          uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress);
            this.uploadPercentage = progress;
            clearTimeout(timer)
            timer = setTimeout(timeout, MILLISECS)

          }, (error) => {
            console.log(error)
            clearTimeout(timer)
            reject()
          }, async() => {

            const imageUrl = `https://firebasestorage.googleapis.com/v0/b/photowishv2/o/events%2F${eventId}%2F${folderName}%2F${filename}?alt=media`
            console.log('upload to storage success')
            this.uploadPercentage = 100
            clearTimeout(timer)

            await delay(1)

            resolve({
              imageUrl: imageUrl,
              key: imagePath
            })
          })
        }
        catch (error) {
          console.log(error)
          // alert(`Something wrong, please try again #aaa : \n ${error}`)
          appStateStore.loading = false
          reject()
        }
      })

      return p
    },

    async creteEnvelopImage(promptpayData: any) {
      
      try {
        const url = 'https://asia-southeast1-photowishv2.cloudfunctions.net/createEnvelopImage-createEnvelopImage'
        const response = await axios.post(url, promptpayData)

        if(response.status === 200) { 
          return response.data
        }
      } catch(err) {
        console.log(err)
      }
    },

    async updatePromptpayFirestore(payload: FormPromptpay) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId
      const promptpayId = payload.promptpayId
      const docRef = doc(db, `events/${eventId}/promptpay/${promptpayId}`)
      await setDoc(docRef, payload, { merge: true })
    },

    async createPromptPayNotifyToDevs(envelopImage: string, payload: FormPromptpay) { // , payload: FormPromptpay
      const eventStore = useEventStore()
      const eventId = eventStore?.eventId
      const lineNotifyId = makeId(10)
      const docRef = doc(db, 'line', lineNotifyId)
      const friendOf = payload?.friendOf === 'bride' ? 'แขกฝ่ายเจ้าสาว' : 'แขกฝ่ายเจ้าบ่าว'
      await setDoc(docRef, {
        to: this.devUsersIds,
        image: envelopImage,
        text: `จากคุณ ${payload.fullName} (${friendOf})`,
        type: "envelop",
        createdAt: new Date().toISOString(),
        eventId: eventId,
      }, { merge: true })
    },

    async createPromptpayNotifyToUsers(envelopImage: string, payload: FormPromptpay) { // , payload: FormPromptpay
      const eventStore = useEventStore()
      const eventId = eventStore?.eventId
      const notifyLineUsers = eventStore.notifyLineUsers

      // photowish.App : send to dev users
      const friendOf = payload?.friendOf === 'bride' ? 'แขกฝ่ายเจ้าสาว' : 'แขกฝ่ายเจ้าบ่าว'
      if(notifyLineUsers?.length > 0) {
        const userLineNotifyId = makeId(10)
        const docRef = doc(db, 'line', userLineNotifyId)
        await setDoc(docRef, {
          to: notifyLineUsers,
          from: 'photowishApp',
          image: envelopImage,
          type: "envelop",
          text: `จากคุณ ${payload.fullName} (${friendOf})`,
          createdAt: new Date().toISOString(),
          eventId: eventId,
        }, { merge: true })
      }
    },

    // ------ delete / remove ------
    async removeOnStorage(destinatoin: string) {
      const appStateStore = useAppStateStore()

      try {
        const desertRef = ref(storage, destinatoin);
        await deleteObject(desertRef)
        console.log('delete on storage success', destinatoin)

      } catch (error) {
        console.log(error)
        alert(`Something wrong, please try again \n ${error}`)
        appStateStore.loading = false
      } finally {
        appStateStore.loading = false
      }
    },

    async removeOnFirestore(promptpayId: string) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId
      
      const docRef = doc(db, `events/${eventId}/promptpay/${promptpayId}`)
      await deleteDoc(docRef)
      console.log('delete on firestore success', promptpayId)
    },
  },
  getters: {}
})


function mockReceipt() {
  return [{
    "eventId": "",
    "promptpayId": "",
    "userId": "",
    "remark": "ขอให้ทั้งคู่มีความสุข ความสำเร็จในความรัก มีความสุขมากๆ",
    "key": "mock-key",
    "bucket": "",
    "fullName": "ตัวอย่างการโอนเงิน",
    "amount": "5000.00",
    "createAt": "2023-07-03T10:08:43.319Z",
    "imageUrl": "https://s359.kapook.com/r/600/auto/pagebuilder/ba154685-db18-4ac7-b318-a4a2b15b9d4c.jpg",
    "sample": true
  }]

}

interface FormPromptpay {
  eventId: string,
  key: string,
  bucket: string,
  promptpayId: string,
  userId: string,
  fullName: string,
  amount: string | number,
  imageUrl: string
  remark: string,
  createAt: string
  friendOf: string
}