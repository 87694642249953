export default defineNuxtRouteMiddleware((to, from) => {
  
  //ไม่โชว์ query หน้า home เพื่อความสวยงาม
  const isWelcomePage = from.fullPath?.includes('/welcome')
  const isWelcomePage2 = from.fullPath?.includes('/event/')
  const isWelcomePage3 = from.fullPath?.includes('/qr/')
  if(isWelcomePage || isWelcomePage2 || isWelcomePage3) {
    return
  }

  if (Object.keys(from.query)?.length && !Object.keys(to.query)?.length) {
    return navigateTo({
      path: to.path,
      hash: to.hash,
      params: to.params,
      query: from.query,
      redirectCode: 302, // default
    });
  }
});