export default defineNuxtRouteMiddleware((to) => {
    return
    const nuxtApp = useNuxtApp(); // Access the Nuxt app instance
  
    // Check if the 'admin' query parameter is set to 'true'
    const isAdmin = to.query.admin === 'true';
  
    // Allow admin users to bypass the maintenance page
    if (isAdmin) {
      // Clean up the URL by removing the `admin` query parameter
      const cleanQuery = { ...to.query };
      delete cleanQuery.admin;
      return navigateTo({ path: to.path, query: cleanQuery });
    }
  
    // Redirect regular users to the maintenance page if they aren't on it already
    if (to.name !== 'maintenance') {
      return navigateTo('/maintenance');
    }
    return 
    // Disable functionalities on the maintenance page
    if (to.name === 'maintenance') {
      // Prevent reactive effects, event listeners, or other services here
      nuxtApp.hook('app:mounted', () => {
        console.log("App functionalities disabled in maintenance mode");
  
        // Example: Detach global event listeners or stop services as needed
        // Detach any global event listeners (if there are any in use)
        window.onbeforeunload = null;
        document.body.innerHTML = "<h1>Site is under maintenance</h1>";
      });
  
      // Prevent server-side processing or extra rendering
      nuxtApp.hook('vue-renderer:ssr:prepareContext', (context) => {
        context.redirected = true; // Stop further processing
      });
    }
  });
  