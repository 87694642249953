import { useCloudFlareId } from "./useCloudFlareImageUrl";
import { useRenderLoading } from "./useRenderLoading";
import { useUseRenderText } from "./useRenderText"

export const useRenderTemplateV1 = ({
  container,
  template,
  wishData,
  variant,
  guestBlobImage,
  debug = false,
  loadingOptions = {}
}) => {

  let stage = null;
  let layer = null;
  let transformer = null;

  let elements = template.elements;
  let background = null;
  let groupPlaceholder = null;
  let placeholder = null;
  let name = null;
  let wish = null;


  let overlays = [];

  const CLOUDFLARE_VARIANT = variant || 'present';
  render()

  return {
    stage
  }
  function renderLower() {
    let _layer = layer

    //group
    groupPlaceholder = new Konva.Group({
      x: elements.placeholder.x,
      y: elements.placeholder.y,
      name: 'groupPlaceholder',
      clip: {
        x: 0,
        y: 0,
        width: elements.placeholder.width * elements.placeholder.scaleX,
        height: elements.placeholder.height * elements.placeholder.scaleY,
      }
    });

    //placeholder
    let placeholderHtml = new Image();
    placeholderHtml.crossOrigin = "Anonymous";
    placeholder = new Konva.Image({
      ...elements.placeholder,
      x: 0,
      y: 0,
      image: placeholderHtml,
      opacity: 0,
    });
    placeholderHtml.onload = function () {
      placeholder.setAttrs({
        ...wishData.guest.imageAttrs,
        x: 0,
        y: 0,
      });

      _layer.draw();
    };
    placeholderHtml.src = useCloudFlareId(elements.placeholder.cfId, CLOUDFLARE_VARIANT) || elements.placeholder.url;

    //background
    let bgHtml = new Image();
    bgHtml.crossOrigin = "Anonymous";
    background = new Konva.Image({
      ...elements.background,
      image: bgHtml,
      name: 'background',
      listening: false,
      cfId: elements.background.cfId,
      opacity: 1,
    });
    bgHtml.onload = function () {
      // let nameimage = removeColor(bgHtml, [0, 255, 0], 20);
      background.image(bgHtml);
      _layer.draw();
    };
    bgHtml.src = useCloudFlareId(elements.background.cfId, CLOUDFLARE_VARIANT) || elements.background.url;

    //add text Name
    let n = `${elements.name.prefix || ""} ${wishData.guest.name} ${elements.name.suffix || ""}`.trim()
    name = new Konva.Text({
      ...elements.name,
      text: n
    });

    //add text Wish
    wish = new Konva.Text({
      ...elements.wish,
      text: wishData.wish.text,
      words : wishData.wish.words,
      fontStyle: wishData.wish.fontStyle == 'bold' ? 'normal' : wishData.wish.fontStyle  ,
    });

    // lowwer
    groupPlaceholder.add(placeholder);
    _layer.add(groupPlaceholder);
    _layer.add(background);
    _layer.add(name);
    _layer.add(wish);
  }

  function render() {

    let containerWidth = document.getElementById(container).clientWidth
    let containerHeight = document.getElementById(container).clientHeight

    var sceneWidth = template.width
    var sceneHeight = template.height

    let scale = containerWidth / sceneWidth
    if (containerHeight != 0) {
      scale = Math.min(containerWidth / sceneWidth, containerHeight / sceneHeight);
    }


    stage = new Konva.Stage({
      container: container || 'container',
      width: template.width * scale,
      height: template.height * scale,
      scaleX: scale,
      scaleY: scale,
    });
    layer = new Konva.Layer();
    elements = template.elements;
    stage.add(layer);
    renderLower();
    sortOrderLayers()
    replaceGuestImage();
    debug && setTimeout(() => {
      debugMode();
    }, 1000);
    useUseRenderText({ layer, wish, name,template })
    useRenderLoading({ loadingOptions, layer, placeholder, name, wish, background })

  }
  function sortOrderLayers() {
    if (elements.placeholder.mode === 'upper') {
      background.zIndex(0);
      groupPlaceholder.zIndex(1);
      name.zIndex(2);
      wish.zIndex(3);
    } else {
      groupPlaceholder.zIndex(0);
      background.zIndex(1);
      name.zIndex(2);
      wish.zIndex(3);
    }
  }
  function replaceGuestImage() {

    let geustImage = wishData.guest.image.cfId

    if (elements.placeholder.mode === 'upper') {
      geustImage = wishData.guest.image.cfRemovebgId
    }
    let placeholderHtml = new Image();
    placeholderHtml.crossOrigin = "Anonymous";
    placeholderHtml.onload = function () {

      groupPlaceholder.setAttrs({
        x: wishData.guest.imageAttrs.x,
        y: wishData.guest.imageAttrs.y,
        clip: null,
      })

      placeholder.setAttrs({
        ...wishData.guest.imageAttrs,
        x: 0,
        y: 0,
        opacity: 1,
      });

      placeholder.image(placeholderHtml);

      layer.draw();
    };

    if(elements.placeholder.mode === 'upper') {
      placeholderHtml.src = useCloudFlareId(geustImage, CLOUDFLARE_VARIANT) || wishData.guest.image.original
    } else {
      placeholderHtml.src = guestBlobImage || useCloudFlareId(geustImage, CLOUDFLARE_VARIANT) || wishData.guest.image.original
    }
  }

  function debugMode() {

    drawLineAllBorder(placeholder, groupPlaceholder);
    drawLineAllBorder(background);
    drawLineAllBorder(name);
    drawLineAllBorder(wish);

    background.opacity(0.5);

  }

  function drawLineAllBorder(shape, parent) {
    let border = new Konva.Line({
      points: [
        shape.x(),
        shape.y(),
        shape.x() + shape.width() * shape.scaleX(),
        shape.y(),
        shape.x() + shape.width() * shape.scaleX(),
        shape.y() + shape.height() * shape.scaleY(),
        shape.x(),
        shape.y() + shape.height() * shape.scaleY(),

      ],
      stroke: 'red',
      strokeWidth: 2,
      closed: true,
      listening: false,
    });
    if (parent) {
      parent.add(border);
    } else {
      layer.add(border);
    }

  }

  function removeColor(img, color, tolerance) {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      if (data[i] > color[0] - tolerance && data[i] < color[0] + tolerance &&
        data[i + 1] > color[1] - tolerance && data[i + 1] < color[1] + tolerance &&
        data[i + 2] > color[2] - tolerance && data[i + 2] < color[2] + tolerance) {
        data[i + 3] = 0;
      }
    }
    ctx.putImageData(imageData, 0, 0);
    return canvas;
  }

}
