import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'
import { filterTemplate } from '~~/utils/filterTemplate'

import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'

import _map from 'lodash/map.js'
import _find from 'lodash/find.js'
import _forEach from 'lodash/forEach.js'
import _concat from 'lodash/concat.js'
import _sample from 'lodash/sample.js'
import _cloneDeep from 'lodash/cloneDeep.js'
import _uniq from 'lodash/uniq.js'
import _uniqBy from 'lodash/uniqBy.js'
import _includes from 'lodash/includes.js'
import _filter from 'lodash/filter.js'
import _take from 'lodash/take.js'
import { useEventStore } from './event'
import { useWishStore } from './wish'
import FontFaceObserver from 'fontfaceobserver'

import delay from '~/utils/delay'
import { useUploadStore } from './upload'
import { mappingTemplateId } from '~/utils/mappingTemplateId'

export const useTemplateStore = defineStore({
  id: 'template',
  state: () => {
    return {
      templateUsedIds: [] as Array<string>, //[ $templateId... ]
      templateList: [],
      templateId: null as any,
      imageAttrs: null as any,
      template_1: null as any,
      template_2: null as any,
      template_3: null as any,
      templateUrlList: [],
    }
  },
  actions: {
    async main() {
      await this.fetchTemplateList()
      this.generateRandomTemplates()

      await this.initPrefetchBackground()
    },

    fetchTemplateList() {
      const wishStore = useWishStore()
      const myWishes = wishStore.getMyWishList


      this.templateUsedIds = _map(this.templateUsedIds, (id) => mappingTemplateId(id))
      // this.templateUsedIds = getUniqTemplateIdList(this.templateUsedIds, myWishes)
      const allTemplates = getUniqTemplateIdList(this.templateUsedIds, myWishes)

      const p = new Promise(async resolve => {
        // mock กันพังถ้าไม่ได้เลือกมา
        if (allTemplates?.length === 0) {
          this.templateUsedIds = ['standard001pro', 'standard002pro', 'remove001pro', 'remove002pro']
        }

        const promiseList = []
        for (let index = 0; index < allTemplates?.length; index++) {
          const templateId = allTemplates[index];

          const docRef = doc(db, 'templates', templateId)
          const pm = getDoc(docRef)
          promiseList.push(pm)
        }

        const pmResponses = await Promise.all(promiseList)

        const tmpList: any = _map(pmResponses, (response) => response.data())
        const filtered = _filter(tmpList, t => t?.templateId)

        const mergeList = _concat(this.templateList, filtered)
        const uniqList = _uniqBy(mergeList, 'templateId')
        this.templateList = uniqList
        resolve(true)
      })

      return p
    },

    generateRandomTemplates() {
      const templateList = _filter(this.templateList, t => t?.version === '3')

      const filteredTemplate = filterTemplate(templateList)
      let { standardList, removeList, defaultStandardList, defaultRemoveList } = filteredTemplate

      this.template_1 = removeList?.length > 0 ? _sample(removeList) : defaultRemoveList[0]

      if (standardList?.length === 0 || standardList?.length < 2) {
        standardList = [...standardList, defaultStandardList[0], defaultStandardList[1]]
        standardList = _uniq(standardList)
      }

      const takeList = _take(standardList, 2) || defaultStandardList
      this.template_2 = takeList[0]
      this.template_3 = takeList[1]

      console.log('template_1', this.template_1)
      console.log('template_2', this.template_2)
      console.log('template_3', this.template_3)
    },

    // --- prefetch ---
    async initPrefetchBackground() {

      const list = _filter(this.templateList, t => {
        if(t?.templateId === this.template_1 || t?.templateId === this.template_2 || t?.templateId === this.template_3) {
          return t
        }
      })

      const cfIdList = _filter(list, t => t?.elements?.background?.cfId)
      const backgrounds = _map(cfIdList, t => `https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/${t?.elements?.background?.cfId}/original`)

      for (let index = 0; index < backgrounds.length; index++) {
        const url = backgrounds[index];
        await this.prefetchBackground(url)
      }
    },

    prefetchBackground(url: string) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          console.log('prefetch success: ', url)
          resolve(true)
        }
        img.crossOrigin = "Anonymous"
        img.src = url
      })
    },
    // --------------------------

    randomOneTemplateId() {
      const list = _concat(this.template_1, this.template_2, this.template_3)
      this.templateId = _sample(list)
    },

    randomNewTemplateByType(type: string) {
      const templateList = this.templateList

      const filteredTemplate = filterTemplate(templateList)
      const { standardList, removeList } = filteredTemplate

      let targetTemplate = ''

      if (type === 'template_1') {
        targetTemplate = _sample(removeList)
        this.template_1 = targetTemplate
        console.log('new random template_1: ', this.template_1)
      }

      if (type === 'template_2') {
        targetTemplate = _sample(standardList)
        this.template_2 = targetTemplate
        console.log('new random template_2: ', this.template_2)
      }

      if (type === 'template_3') {
        targetTemplate = _sample(standardList)
        this.template_3 = targetTemplate
        console.log('new random template_3: ', this.template_3)
      }
    },

    selectNewTemplateByTemplateId(type: string, newTemplateId: string) {
      if (type === 'template_1') {
        this.template_1 = newTemplateId
        console.log('new template_1: ', this.template_1)
      }

      if (type === 'template_2') {
        this.template_2 = newTemplateId
        console.log('new template_2: ', this.template_2)
      }

      if (type === 'template_3') {
        this.template_3 = newTemplateId
        console.log('new template_3: ', this.template_3)
      }
    },

    async renderEditor(elementId: string, wishData: any) {
      const eventStore = useEventStore()
      const uploadStore = useUploadStore()
      const templateId = this.templateId

      const templateData = _find(this.templateList, t => t.templateId === templateId)

      const { stage, zoomIn, zoomOut, zoomReset, exportWishData } = await useRenderEditorV3({
        container: elementId,
        template: templateData,
        wishData: wishData,
        variant: 'present',
        guestBlobImage: uploadStore?.blobUrl || false,
      })

      return {
        stage,
        zoomIn,
        zoomOut,
        zoomReset,
        exportWishData,
      }
    },

    async renderPreview(elementId: string, wishData: any, templateTarget = null, attrs = null) {
      const eventStore = useEventStore()
      const uploadStore = useUploadStore()

      const GUEST_VARIANT_IMAGE = 'w=600' // 'original'

      const templateId = templateTarget ? templateTarget : this.templateId
      const templateData = _find(this.templateList, t => t.templateId === templateId)

      let newWishData = wishData
      if (attrs) {
        newWishData.guest['imageAttrs'] = attrs
      }

      debugger
      let result = null
      if (newWishData?.version === 'v3') {
        result = await useRenderTemplateV3({
          container: elementId,
          template: templateData,
          wishData: newWishData,
          variant: GUEST_VARIANT_IMAGE,
          guestBlobImage: uploadStore?.blobUrl || false
        })
      } else {
        result = await useRenderTemplateV1({
          container: elementId,
          template: templateData,
          wishData: newWishData,
          variant: GUEST_VARIANT_IMAGE,
          guestBlobImage: uploadStore?.blobUrl|| false
        })
      }

      return result?.stage || null
    },

    // ----- for test -----
    async renderTestEditorDebug(elementId: string, wishData: any) {
      const eventStore = useEventStore()
      const templateId = this.templateId

      const templateData = _find(this.templateList, (t) => {
        return t.templateId === templateId
      })

      console.log('templateData -->', templateData)

      const newWishData = {
        ...wishData,
        templateId: templateId
      }

      const { stage, zoomIn, zoomOut, zoomReset, exportWishData } = await useRenderEditorV3({
        container: elementId,
        template: templateData,
        wishData: newWishData,
        variant: 'present'
      })

      return {
        stage,
        zoomIn,
        zoomOut,
        zoomReset,
        exportWishData
      }
    },
  },
  getters: {
    getTemplateData( ) {
      return (templateId) => {
        debugger
        let templatIdMap = mappingTemplateId(templateId)
        const templateData = _find(this.templateList, t => t.templateId === templatIdMap)
        return templateData
      }
    }
  }
})

//  ----- utils -----
function getUniqTemplateIdList(templateIds: any, myWishes: any) {

  const wishedTemplateIds = _map(myWishes, w => w?.templateId)
  const mergeList = _concat(templateIds, wishedTemplateIds)

  const uniqList = _uniq(mergeList)

  return uniqList
}