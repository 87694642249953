export const useCloudFlareId = (imageId, variant  ) => {
  const ACCOUNT_HASH = 'G-5q2BMDs1WUCh3jAuvl7w'
  if (!imageId || !variant) {
    return null
  }

  if (variant  == 'raw') {
    return `https://imagedelivery.net/${ACCOUNT_HASH}/${imageId}/gamma=0`
  }

  return `https://imagedelivery.net/${ACCOUNT_HASH}/${imageId}/${variant}`

}
