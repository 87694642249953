import { ref, reactive } from 'vue';
import FontFaceObserver from 'fontfaceobserver'
// import w2 from './worker.js'

export function useRenderLoading({

  layer: layer_elements,
  placeholder,
  background,
  name,
  wish,
  loadingOptions = {},
}) {

  //next wait time. 10 secs
  let TIMER_LIST = [100, 500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000]

  let elements = ref([])
  start()
  return {
    block,
    unblock,

    start,
  }
  function block( ) {
    let loading = new Konva.Rect({
      x: 0,
      y: 0,
      width: background.width(),
      height: background.height(),
      fill: loadingOptions.background || 'white',
      opacity: 1,
      draggable: false,
    });
    layer_elements.add(loading);
    // add text loading
    let loadingText = new Konva.Text({
      x: background.width() / 2,
      y: background.height() / 2,
      text: loadingOptions.text || 'Loading...',
      // text: 'Loading...',
      fontSize: 80,

      fill: 'gray',
      draggable: false,
    });
    loadingText.offsetX(loadingText.width() / 2);
    loadingText.offsetY(loadingText.height() / 2);
    layer_elements.add(loadingText);

    elements.value.push(loading)
    elements.value.push(loadingText)
  }

  function unblock() {
    elements.value.forEach(element => {
      element.remove()
    })
    elements.value = []
  }

  function addPromise(promise) {
    promiseList.value.push(promise)
  }

  //check if image is loaded
  function imgLoaded(imgElement) {
    return imgElement.complete && imgElement.naturalHeight !== 0;
  }

  function start() {
    block()


    let checkImageList = [
      background.image(),
      placeholder.image(),
    ]

    function checkAllImagesLoaded(imageList: any[]): boolean {
      return imageList.every(img => imgLoaded(img));
    }

    let timer = 0;
    let timerIndex = 0;
    let timerMax = TIMER_LIST.length;

    // you import worker from './worker.js' current directory
    let meta = import.meta.url
    const w = new Worker(new URL('./worker.js', import.meta.url))


    w.onmessage = checkImages

    function checkImages(event) {
      console.log('Message received from worker', event.data)
      timer += TIMER_LIST[timerIndex];
      if (checkAllImagesLoaded(checkImageList)) {
        unblock();
        w.terminate()
        return;
      }
      timerIndex++;
      if (timerIndex >= timerMax) {
        unblock();
        w.terminate()
        return;
      }
      startTimer();
    }
    function startTimer() {
      // todo to be fixed with  webworker settimeout
      // setTimeout(startTimer, TIMER_LIST[timerIndex]);
      w.postMessage({
        cmd: 'start',
        timer: TIMER_LIST[timerIndex],
      })
    }

    startTimer();

  }


}
