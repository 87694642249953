export const ALL_FLOWS = [
  {
    title: 'splash screen',
    titleTH: '',
    titleEN: '',
    path: '/',

    self_service: true,
    dslr: true,

    navbar: false,
    footer: false,

    nextButton: false,
    backButton: false,
  },
  // {
  //   title: 'Welcome',
  //   titleTH: 'ยินดีต้อนรับสู่งานแต่งของเรา',
  //   titleEN: 'Welcome to our wedding',
  //   path: '/welcome',
  //   self_service: true,
  //   dslr: true,

  //   navbar: false,
  //   footer: false,

  //   nextButton: false,
  //   backButton: false,
  // },
  {
    title: 'Welcome',
    titleTH: 'ยินดีต้อนรับสู่งานแต่งของเรา',
    titleEN: 'Welcome to our wedding',
    path: '/event/',
    self_service: true,
    dslr: true,

    navbar: false,
    footer: false,

    nextButton: false,
    backButton: false,
  },
  {
    title: 'Welcome',
    titleTH: 'ยินดีต้อนรับสู่งานแต่งของเรา',
    titleEN: 'Welcome to our wedding',
    path: '/qr/',
    self_service: true,
    dslr: true,

    navbar: false,
    footer: false,

    nextButton: false,
    backButton: false,
  },

  // -------- upload - wish ------------
  // step: 1/3
  {
    title: 'UploadAndWish',
    titleTH: 'เลือกรูป & อวยพร (1/3)',
    titleEN: 'Select Photo & Wish (1/3)',
    path: '/selectUploadAndWish',

    self_service: true,
    dslr: false,

    self_service_step: 1,
    dslr_step: null,

    stepTitleTH: 'รูป & อวยพร',
    stepTitleEN: 'Photo & Wish',

    navbar: true,
    footer: true,

    nextButton: true, // ตอนเริ่ยังไม่แสดง เมื่ออัพโหลดรูปแล้วค่อยแสดงปุ่ม
    backButton: false,
  },
  // step: 2/3
  {
    title: 'Template',
    titleTH: 'เลือกเทมเพลต (2/3)',
    titleEN: 'Select Template (2/3)',
    path: '/selectTemplate',

    self_service: true,
    dslr: false,

    self_service_step: 2,
    dslr_step: 2,

    stepTitleTH: 'เทมเพลส',
    stepTitleEN: 'Templates',

    navbar: true,
    footer: false,

    nextButton: false,
    backButton: false,
  },
  // step: 3/3
  {
    title: 'Editor',
    titleTH: 'ปรับแต่งตำแหน่งรูป (3/3)',
    titleEN: 'Edit your photo (3/3)',
    path: '/editor',

    self_service: true,
    dslr: false,

    self_service_step: 3,
    dslr_step: 3,

    stepTitleTH: 'รีวิว',
    stepTitleEN: 'Review',

    navbar: true,
    footer: true,

    nextButton: true,
    backButton: true,
  },
  // thankyou
  {
    title: 'Thankyou',
    titleTH: 'ขอบคุณที่สำหรับคำอวยพรของคุณ',
    titleEN: 'Thank you for your wish',
    path: '/thankyou',

    self_service: true,
    dslr: true,

    navbar: false,
    footer: false,

    nextButton: false,
    backButton: false,
  },
  // special page
  {
    title: 'My wishes',
    titleTH: 'คำอวยพรของฉัน',
    titleEN: 'My wishes',
    path: '/mywish',

    self_service: true,
    dslr: true,

    navbar: true,
    footer: false,

    nextButton: false,
    backButton: false,
  },
  // special page
  {
    title: 'Preview Wish',
    titleTH: 'ตัวอย่างคำอวยพร',
    titleEN: 'Preview Wish',
    path: '/preview',

    self_service: true,
    dslr: true,

    navbar: false,
    footer: false,

    nextButton: false,
    backButton: false,
  },
  {
    title: 'Promptpay',
    titleTH: 'แนปสลิปพร้อมเพย์',
    titleEN: 'Envelope',
    path: '/promptpay',

    self_service: true,
    dslr: true,

    navbar: true,
    footer: false,

    nextButton: false,
    backButton: false,
  },
  {
    title: 'Promptpay_Attach',
    titleTH: 'แนปสลิบ',
    titleEN: 'Attach',
    path: '/promptpay-attach',

    self_service: true,
    dslr: true,

    navbar: true,
    footer: false,

    nextButton: false,
    backButton: false,
  },
]