 import Rollbar from 'rollbar' ;
import { useAuthStore } from '~~/store/auth';
import { useEventStore } from '~~/store/event';

export default defineNuxtPlugin((nuxtApp) => {

    //check if http or local return
   
    let isLocal = window.location.protocol == 'http:'
    if (isLocal) {
        return
    }

    const rollbar = new Rollbar({
        accessToken: "88a882fb95014b7fa3f8d3a7886a0e92",
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            environment: 'production',
            // person:
            // {
            //     id: eventData.eventId,
            //     username: `[${eventData.eventId}] `,
            // }
        }
    });

    let eventStore = useEventStore()
    watch( ()=> eventStore.eventId, (a,b) => {
          
        useAuthStore().userId
        rollbar.configure({
            payload: {
                person:
                {
                    id: eventStore.eventId,
                    username: `${useAuthStore().userId}`,
                }
            }
        });
    })

    nuxtApp.provide('rollbar', rollbar)

    nuxtApp.vueApp.config.errorHandler = (err, vm, info) => {
         
        rollbar.error(err, vm, info)
    }
  })