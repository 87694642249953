import { useAppStateStore } from '~/store/appState'
import { useWishStore } from '~/store/wish'
import { useEventStore } from '~/store/event'
import { realtimeDatabase } from '~/service/firebase_config'
import { ref, set, remove, onDisconnect } from 'firebase/database'
import { useStorage , useSessionStorage} from '@vueuse/core'
import { ref as refState} from 'vue'
import dayjs from 'dayjs'
import _debounce from 'lodash/debounce.js'

const trackingAnalytic = () => {
  const route = useRoute()
  const appStateStore = useAppStateStore()
  const wishStore = useWishStore()
  const eventStore = useEventStore()

  let state = reactive({
    refDateAnalytic: '',
    refMonthAnalytic: '',

  })

  watch(() => route.path, () => {

    // update only thankyou page
    if(route.path === '/thankyou') {
      updateDateAnalytic()
      updateMonthAnalytic()
    }
  })

  const updateDateAnalytic = () => {
    const currentMonth = dayjs().format('YYYY-MM-DD')
    state.refDateAnalytic = `analytic/date/${currentMonth}/${eventStore.eventId}`
    return set(ref(realtimeDatabase, state.refDateAnalytic), wishStore.currentWishesCount + 1)
  }


  const updateMonthAnalytic = () => {
    const currentMonth = dayjs().format('YYYY-MM')
    state.refMonthAnalytic = `analytic/events/${currentMonth}/${eventStore.eventId}`
    return set(ref(realtimeDatabase, state.refMonthAnalytic), wishStore.eventWishesCount + 1)
  }

  return {
    updateDateAnalytic,
    updateMonthAnalytic
  }

}

export default trackingAnalytic