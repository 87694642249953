import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs"


const config = {
  region: 'ap-southeast-1',
  credentials: {
    accessKeyId: 'AKIAW4FR4ZMJTA76ZQXI',
    secretAccessKey: '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'
  }
}
const client = new SQSClient(config);


export async  function sqsPublishMessage(wish: any) {
  const messageParams = {
    QueueUrl: 'https://sqs.ap-southeast-1.amazonaws.com/472819485459/photowish_b2c_v2_wish_render_image',
    MessageBody: JSON.stringify(wish)
  };

  try {
    const data = await client.send(new SendMessageCommand(messageParams));
    console.log("Send SQS message success.",  data);
  } catch(err: any) {
    console.log("Error", err.stack);
  }
}
