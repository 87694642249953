import { defineStore } from 'pinia'
import { db, storage, realtimeDatabase } from '~/service/firebase_config'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { ref as refRealtime, onValue} from "firebase/database";
import { useAppStateStore } from './appState'
import { useAuthStore } from './auth'
import { useEventStore } from './event'
import { useWishStore } from './wish'
import { ref, uploadBytesResumable, uploadBytes } from "firebase/storage";
import axios from 'axios';

import _size from 'lodash/size.js'
import { makeId } from '../utils/makeId'


export const useUploadStore = defineStore({
    id: 'upload',
    state: () => {
      return {
        blobUrl: null as any,
        uploadForm : null,

          //tobe removed
        imageLayout: 'landscape',
        selectImageType: null as any,
        uploadPercentage: 0,

        uploadInstance: null as any,
      }
    },
    actions : {
        async main() {
            const $route = useRoute()
        },
        checkConnectionState() {
          const p = new Promise((resolve) => {
            const connectedRef = refRealtime(realtimeDatabase, ".info/connected");
            const unsubscribe = onValue(connectedRef, (snap) => {
              if (snap.val() === true) {
                console.log("connected");
                resolve(true)
              } else {
                console.log("not connected");
                resolve(false)
              }
            });

            setTimeout(() => {
              unsubscribe();
              console.log('unsub realtime .info/connected')
            }, 3000);
          })

          return p
        },

        uploadImage(fileType, imageBase64) {
          const authStore = useAuthStore()
          const appStateStore = useAppStateStore()
          const eventStore = useEventStore()
          const wishStore = useWishStore()
          const eventId = eventStore.eventId
          const qrId = wishStore.qrId
          const docId = wishStore.wishDocId
          const userId = authStore.userId
          const hashId = wishStore.getHashId
        
          const p = new Promise(async (resolve, reject) => {
    
            // Check image type
            let imageType = 'jpg'
            if (fileType === 'image/jpeg') {
              imageType = 'jpg'
            }
    
            if (fileType === 'image/png') {
              imageType = 'png'
            }

            if (fileType === 'image/heic') {
              imageType = 'heic'
            }
    
            try {
              const tmpName:any = getOnlyCustomId(docId)
              const fileUpload = await dataUrlToFile(imageBase64, tmpName, fileType)
              const filename = qrId ?  `${eventId}:${qrId}.${imageType}` :  `${eventId}:${userId}:${hashId}.${imageType}`
    
              let uploadPayload: any = {
                fileUpload,
                filename,

                customMetadata: {
                  'path': 'wish_users',
                  'project': 'photowishv2',
                  'eventId': eventId,
                  'userId': authStore.userId,
                  'hashId': wishStore.getHashId,
                  'wishId': docId,
                  "removebg": true
                }
              }
    
              if (qrId) {
                uploadPayload['qrId'] = qrId
              }

                // this.uploadPercentage = 0
                // resolve('success')
    
              const upload = await uploadFileToStorage(uploadPayload, (percent) => {
                this.uploadPercentage = percent
                console.log(percent)
              })
    
              const docRef = doc(db, `events/${eventStore.eventId}/wishes/${docId}`)
              await setDoc(docRef, {
                layout: this.imageLayout
              }, { merge: true })
    
              if (upload) {
                console.log('upload to storage success')
    
                this.uploadPercentage = 100
                await delay(1)
                setTimeout(() => {
                  this.uploadPercentage = 0
                }, 1000);
                resolve('success')
              }
            }
            catch (error) {
              console.log(error)
              alert(`Something wrong, please try again \n ${error}`)
              appStateStore.loading = false
              reject()
            }
          })
    
          return p
        },

        uploadImageMock() {
            const p = new Promise(async (resolve) => {
              this.uploadPercentage = 20
              console.log('this.uploadPercentage -->', this.uploadPercentage)
              await delay(1)
      
              this.uploadPercentage = 50
              console.log('this.uploadPercentage -->', this.uploadPercentage)
              await delay(1)
      
              this.uploadPercentage = 85
              console.log('this.uploadPercentage -->', this.uploadPercentage)
              await delay(1)
      
              this.uploadPercentage = 100
              console.log('this.uploadPercentage -->', this.uploadPercentage)
              await delay(1)
      
              this.uploadPercentage = 0
              resolve(true)
            })
      
            return p
        },

        saveData(_data) { 
          if(_size(_data) == 0) {
            this.uploadInstance = null
          }
          this.uploadInstance = _data

        },

        // --- upload line profile ----
        uploadLineProfile(filename: string, base64: any, promptpayId: string) {
          const appStateStore = useAppStateStore()
          const authStore = useAuthStore()
          const userId = authStore.userId
          const eventStore = useEventStore()
          const eventId = eventStore.eventId
        
          const p = new Promise(async (resolve, reject) => {
            try {
              const fileType = 'image/jpeg'
              const fileUpload = await dataUrlToFile(base64, filename, fileType)
              const folderName = 'promptpay_line'
              
              // const imagePath = `events/${eventId}/${folderName}/${filename}` // storage
              const imagePath = `queue/${filename}` // cloudflare
              const storageRef = ref(storage, imagePath);
              const metadata = {
                customMetadata: {
                  path: "promptpay_line",
                  project: 'photowishv2',
                  eventId: eventId,
                  userId: userId,
                  promptpayId: promptpayId
                }
              }
              const uploadTask = await uploadBytes(storageRef, fileUpload, metadata)
  
              if (uploadTask) {
                const imageUrl = `https://firebasestorage.googleapis.com/v0/b/photowishv2/o/events%2F${eventId}%2F${folderName}%2F${filename}?alt=media`
                console.log('upload line profile to storage success')
                
                resolve({
                  imageUrl: imageUrl,
                  key: `${eventId}/${folderName}/${filename}`
                })
              }
            }
            catch (error) {
              console.log(error)
              alert(`Something wrong, please try again \n ${error}`)
              appStateStore.loading = false
              reject()
            }
          })
    
          return p
        },

        // --------- helper ----------
        getBase64Image (imageUrl: string) {
          return new Promise(resolve => {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", imageUrl, true);
            xhr.responseType = "blob";
            xhr.onload = function (e) {
              var reader = new FileReader();
              reader.onload = function(event) {
                var res = event.target!.result;
                resolve(res)
              }
              var file = this.response;
              reader.readAsDataURL(file)
            };
            xhr.send()
          })
        },

        // cloudflare
        async removeOnCloudflare(imageId: string) {
          try {
            const url = 'https://deleteimage-cloudflaredeleteimage-m4gzben4za-as.a.run.app'
            const response = await axios.post(url, {
              imageId: imageId
            })
    
            if(response.status === 200) { 
              return true
            }
          } catch(err) {
            console.log(err)
          }
        },
    }
})

// CHECK IF IMAGE EXISTS
function checkIfImageExists(url: string, callback: any) {
    const img = new Image();
    img.src = url;
  
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
  
      img.onerror = () => {
        callback(false);
      };
    }
  }
  
  
  function getOnlyCustomId(docId: string) {
    if (!docId) {
      return
    }
  
    const docIdSplit = docId.split(':') // [eventId, useId/qrId, uniqString]
    const resultCustomId = docIdSplit?.length >= 3 ? `${docIdSplit[1]}:${docIdSplit[2]}` : docIdSplit[1]
  
    return resultCustomId
  }
  
  
  async function dataUrlToFile(dataUrl: string, fileName: string, imageType: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: imageType });
  }
  
  
  function uploadFileToStorage(uploadPayload, callback) {
    const p = new Promise<any>(async (resolve) => {
      const { fileUpload, filename, customMetadata, } = uploadPayload
  
      const imagePath = `queue/${filename}`
      const storageRef = ref(storage, imagePath);
  
      const metadata = {
        customMetadata: customMetadata
      };
  
      const uploadTask: any = uploadBytesResumable(storageRef, fileUpload, metadata)
  
      // get parcentage
      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log(progress);
          callback(progress)
        })
  
      resolve(uploadTask)
    })
  
    return p
  }