// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJjlrMUcTHETMahGo7-iprTGopbs88ZOw",
  authDomain: "photowishv2.firebaseapp.com",
  databaseURL: "https://photowishv2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "photowishv2",
  storageBucket: "photowishv2",
  messagingSenderId: "150081644918",
  appId: "1:150081644918:web:01abbebd58c755cfcbb257",
  measurementId: "G-P37JN0W6KV"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const storage = getStorage(app)
const realtimeDatabase = getDatabase(app)
const firebase = getApp()


export {
  app,
  firebase,
  db,
  storage,
  realtimeDatabase,
}