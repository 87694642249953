import _includes from 'lodash/includes.js';
import _find from 'lodash/find.js';
import { useWishStore } from '~~/store/wish';
import Rollbar from 'rollbar';
import { useAuthStore } from '~~/store/auth';
import { useEventStore } from '~~/store/event';
import { useTemplateStore } from '~~/store/template';

export default defineNuxtPlugin((nuxtApp) => {
     
    return 
    let wishStore = useWishStore()
    let templateStore = useTemplateStore()

    let removeBgImageUrl = null
    let userUploadImageResized = null

    // user upload image
    watch(() => wishStore.wishData, (data) => {

        //  console.log("current wish ", data)

        if (data && data.guest?.image && data.guest?.image?.remove_bg && removeBgImageUrl != data.guest?.image?.remove_bg) {
            removeBgImageUrl = fetchImage(data.guest?.image?.remove_bg)
        }

        if (data && data.guest?.image && data.guest?.image?.original && userUploadImageResized != data.guest?.image?.original) {
            userUploadImageResized = fetchImage(data.guest?.image?.original)
        }
    })


    // background image
    watch(() => templateStore.template_1, (templateId: string) => {
        const templateData = _find(templateStore.templateList, t => t.templateId === templateId)
        if(templateData) {
            const backgroundUrl = templateData?.background?.url
            backgroundUrl && fetchImage(backgroundUrl)
            console.log(`fetched bg template_1 : ${backgroundUrl}`)
        }
    })

    watch(() => templateStore.template_2, (templateId: string) => {
        const templateData = _find(templateStore.templateList, t => t.templateId === templateId)
        if(templateData) {
            const backgroundUrl = templateData?.background?.url
            backgroundUrl && fetchImage(backgroundUrl)
            console.log(`fetched bg template_2 : ${backgroundUrl}`)
        }
    })

    watch(() => templateStore.template_3, (templateId: string) => {
        const templateData = _find(templateStore.templateList, t => t.templateId === templateId)
        if(templateData) {
            const backgroundUrl = templateData?.background?.url
            backgroundUrl && fetchImage(backgroundUrl)
            console.log(`fetched bg template_3 : ${backgroundUrl}`)
        }
    })

    // watch(() => wishStore.getMyWishList, (myWishes) => {
    //     for (let index = 0; index < myWishes.length; index++) {
    //         const wish = myWishes[index];

    //         const templateId = wish.templateId
    //         const templateData = _find(templateStore.templateList, t => t.templateId === templateId)

    //         if(templateData) {
    //             const backgroundUrl = templateData?.background?.url
    //             backgroundUrl && fetchImage(backgroundUrl)
    //         }
    //     }
    // })


    function fetchBgImageFromUrls(urls) {
        urls.forEach(url => {

            if (_includes(url, "https://fonts") || _includes(url, "_single_0001")) {
                return
            }

            url = replaceAll(url, " ", "")
            url = replaceAll(url, '"', "")
            url = replaceAll(url, "'", "")
            url = replaceAll(url, "]", "")
            url = replaceAll(url, ",", "")

            fetchImage(url)
        })
    }


    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }
    // replaceAll('abba', 'a', 'i');          // => 'ibbi'
    // replaceAll('go go go!', 'go', 'move'); // => 'move move move!'
    // replaceAll('oops', 'z', 'y');


    function detectURLs(message) {
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return message.match(urlRegex)
    }

    // fetch image from url
    function fetchImage(url) {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url;
        img.onload = function () { };
        return url;

    }

})