<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>


<script lang="ts" setup>
  import { useAuthStore } from './store/auth'
  import { useEventStore } from './store/event'
  import { useAppStateStore } from './store/appState'
  import { useTemplateStore } from './store/template'
  import { useWishStore } from './store/wish'
  import { usePromptpay } from './store/promptpay'
  import { useRestorePageStore } from './store/restorePage'
  import _includes from 'lodash/includes.js'
  import axios from 'axios'
  import dayjs from 'dayjs'

  const $route = useRoute()
  const $router = useRouter()

  const authStore = useAuthStore()
  const appStateStore = useAppStateStore()
  const eventStore = useEventStore()
  const templateStore = useTemplateStore()
  const wishStore = useWishStore()
  const promptpayStore = usePromptpay()
  const restorePage = useRestorePageStore()

  onMounted(async () => {
    const isDemo = eventStore.getIsDemoAppByQuery()
    eventStore.holderDemo = false
    if(isDemo) {
      window.localStorage.setItem('isDemo', 'true')
      eventStore.holderDemo = true
    }

    const { eventId, qrId, qrcodeId } = eventStore.getQueryAndParams()

    if(eventId) {
      window.localStorage.setItem('eventId', eventId)
    }

    if(qrId) {
      window.localStorage.setItem('qrId', qrId)
    }

    if(qrcodeId) {
      window.localStorage.setItem('qrcodeId', qrcodeId)
      const resultEventId:any = await eventStore.getEventIdByQrcodeId(qrcodeId)

      // if qrcodeId not found eventId
      if(!resultEventId) {
        window.localStorage.removeItem('qrcodeId')
        return $router.push('/qrcodeNotFound')
      }
      // -----------------------------------------

      resultEventId && window.localStorage.setItem('eventId', resultEventId)

    }

    const isOpen:any = await checkIsInLiffOrBrowser()
    if(isOpen) {
      const data = await getEventForRedirect()

      if(!data) {
        window.localStorage.removeItem('eventId')
        useRouter().push('/eventNotFound')
        return 
      }
      
      eventStore.eventData = data
      useRouter().push('/close')
      return 
    }

    await authStore.main()
    const isSuccess = await eventStore.main()
    window.localStorage.removeItem('isDemo')
    window.localStorage.removeItem('qrcodeId')

    if(isSuccess) {
      await Promise.all([
        wishStore.main(),
        appStateStore.main(),
        promptpayStore.fetchPromptpayReceipts(),
        // restorePage.getRestorePage()
      ])

      await templateStore.main()
    } 

    // --- event: brand ------
    if(eventStore?.eventType === 'wedding') {
      const nodeName = `${eventStore?.eventId}:${authStore?.userId}:guestImage`
      window.localStorage.removeItem(nodeName)
    }
  })

  function beforeCreate() {
    throw new Error("Function not implemented.");
  }

  const checkIsInLiffOrBrowser = async () => {
    const { userAgent } = navigator;
    if(!liff.isInClient() && userAgent.includes("Line")) {
      return true
    } 

    return false
  }

  const getEventForRedirect = async () => {
    const currentEventId = window.localStorage.getItem('eventId')
    const data = await eventStore.getEventById(currentEventId)
    return data
  }


</script>


