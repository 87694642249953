import { createI18n } from 'vue-i18n'
import en from '~/static/lang/en.json'
import th from '~/static/lang/th.json'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'th',
  messages: {
    en,
    th
  }
})

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(i18n)
})

export {
  i18n
}
