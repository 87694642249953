export const useRenderTransformer = ({

  layer,
  placeholder,
  groupPlaceholder,
  transformer,
}) => {


  // สร้าง static กรอบให้คงที่ไว้คำนวณขนาดของรูป
  const frame = new Konva.Rect({
    x: 0,
    y: 0,
    width: groupPlaceholder.clip().width,
    height: groupPlaceholder.clip().height,
    stroke: 'black',
    opacity: 0,
    fill: 'orange',
    listening: false,
    rotation: 0,
  })
  groupPlaceholder.add(frame)

  //---
  // สร้าง fake group & placeholder ขยับไปมาเพื่อให้ดูเหมือนว่ามีการขยาย
  const fakeGroupPlaceholder = groupPlaceholder.clone();
  fakeGroupPlaceholder.clip(null);
  layer.add(fakeGroupPlaceholder);
  fakeGroupPlaceholder.moveToTop();
  fakeGroupPlaceholder.hide();

  const placeholderClone = fakeGroupPlaceholder.findOne('.placeholder')
  // --

  init()

  function init() {

    // Add transformer for resizing , no rotation
    transformer = new Konva.Transformer({
      nodes: [placeholder,],
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      ignoreStroke: true,
      rotateEnabled: false,

    });
    transformer.shouldOverdrawWholeArea(true)

    layer.add(transformer);
    placeholder.draggable(true);


    if (placeholder.getAttrs().mode == 'lower') {

      // Add event listeners to enforce constraints
      placeholder.on('transformstart', onDragStart);
      placeholder.on('transform', onTransform);
      placeholder.on('transformend', onDragEnd);
      placeholder.on('dragstart', onDragStart);
      placeholder.on('dragmove', onDragMove);
      placeholder.on('dragend', onDragEnd);
    }

    layer.draw();
  }

  function onDragStart() {

    placeholderClone.setAttrs({
      x: placeholder.x(),
      y: placeholder.y(),
      scaleX: placeholder.scaleX(),
      scaleY: placeholder.scaleY(),
      rotation: placeholder.rotation(),
      width: placeholder.width(),
      height: placeholder.height(),
      image: placeholder.image(),
      opacity: 0.5,
    });
    fakeGroupPlaceholder.show();
  }

  function onDragEnd() {
    fakeGroupPlaceholder.hide();
  }
  function onTransform() {

    var image = placeholder;

    var scaleX = image.scaleX();
    var scaleY = image.scaleY();
    // console.log(' ')
    // console.log('scaleX', scaleX)
    // console.log('scaleY', scaleY)

    // Minimum scale to cover the placeholder
    var minScaleX = frame.width() / image.width()
    var minScaleY = frame.height() / image.height()
    // console.log('minScaleX', minScaleX)
    // console.log('minScaleY', minScaleY)

    let newSC = null

    let max = Math.max(minScaleX, minScaleY)
    // Enforce minimum scale
    if (scaleX < minScaleX) {
      newSC = max;
    }
    if (scaleY < minScaleY) {
      newSC = max;
    }

    image.scaleX(newSC || scaleX);
    image.scaleY(newSC || scaleY);

    // Ensure image covers placeholder after scaling
    var pos = image.position();
    var scaledWidth = image.width() * scaleX;
    var scaledHeight = image.height() * scaleY;

    var minX = frame.width() - scaledWidth;
    var minY = frame.height() - scaledHeight;

    if (pos.x < minX) {
      pos.x = minX;
    }
    if (pos.x > 0) {
      pos.x = 0;
    }
    if (pos.y < minY) {
      pos.y = minY;
    }
    if (pos.y > 0) {
      pos.y = 0;
    }

    image.position(pos);

    //raotation -150 to - 210
    if (image.rotation() < -150 && image.rotation() > -210) {
      image.rotation(0);
    }
    mirrorImageToPlaceholderClone();
  }

  // Function to handle dragmove events (moving)
  function onDragMove() {


    var image = placeholder;

    var pos = image.position();
    var scaleX = image.scaleX();
    var scaleY = image.scaleY();
    var scaledWidth = image.width() * scaleX;
    var scaledHeight = image.height() * scaleY;

    var minX = frame.width() - scaledWidth;
    var minY = frame.height() - scaledHeight;

    if (pos.x < minX) {
      pos.x = minX;
    }
    if (pos.x > 0) {
      pos.x = 0;
    }
    if (pos.y < minY) {
      pos.y = minY;
    }
    if (pos.y > 0) {
      pos.y = 0;
    }

    image.position(pos);
    mirrorImageToPlaceholderClone();
  }

  function mirrorImageToPlaceholderClone() {

    placeholderClone.setAttrs({
      x: placeholder.x(),
      y: placeholder.y(),
      scaleX: placeholder.scaleX(),
      scaleY: placeholder.scaleY(),
      rotation: placeholder.rotation(),
      width: placeholder.width(),
      height: placeholder.height(),
    });
  }
}
